<template>
  <div class="investment_alert" :class="quantity > 0 ? 'investment_alert_success' : 'investment_alert_warning'">
    <p v-if="quantity > 0" class="title">Parabéns! Você acreditou e investiu em {{quantity}} empresas, acompanhe abaixo os detalhes de cada operação.</p>
    <p v-else class="title">Você ainda não tem nenhum investimento concluído na Tutu Digital, fique ligado nas próximas rodadas!</p>
  </div>
</template>

<script>
export default {
  props: ['quantity']
}
</script>

<style lang="sass" scoped>

.investment_alert
  @apply w-full border-2 rounded-lg py-3 px-2 text-center
  @screen lg
    @apply flex-row items-end
  &_success
    @apply bg-white text-primary border-primary
  &_warning
    @apply bg-white text-secondary border-secondary

.title
  @apply text-sm font-bold
  @screen lg
    @apply text-base

</style>

import { render, staticRenderFns } from "./MyInvestments.vue?vue&type=template&id=7f601276&scoped=true&"
import script from "./MyInvestments.vue?vue&type=script&lang=js&"
export * from "./MyInvestments.vue?vue&type=script&lang=js&"
import style0 from "./MyInvestments.vue?vue&type=style&index=0&id=7f601276&lang=sass&scoped=true&"
import style1 from "./MyInvestments.vue?vue&type=style&index=1&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f601276",
  null
  
)

export default component.exports
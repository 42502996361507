<template>
<div class="modal">
  <div class="backdrop" @click="$emit('closeModal')"/>
    <div class="container flex justify-center items-center">
      <div class="content">
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <div class="content_modal">
          <img v-if="blobType !== 'application/pdf'" class="image" :src="blob">
          <embed v-else class="w-full h-136 select-none" :src="blob" pluginspage="http://www.adobe.com/products/acrobat/readstep2.html">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['blob', 'blobType']
}
</script>

<style lang="sass" scoped>

.modal
  @apply fixed flex items-center justify-center z-50 w-screen h-screen top-0 left-0

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full h-full bg-white z-50 flex flex-col justify-center pb-6 pt-8 rounded-lg
  @screen lg
    @apply

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

.content_modal
  @apply flex flex-col w-full px-4
  @screen lg
    @apply px-8

.image
  @apply object-contain
  max-height: 90vh

.title
  @apply font-bold text-lg text-black text-center uppercase text-primary mb-2
  @screen lg
    @apply text-xl

</style>

<template>
  <div class="flex flex-wrap">
    <view-file-modal v-if="blob" :blob="blob" blobType="application/pdf" @closeModal="blob = null"></view-file-modal>
    <my-investment-modal v-if="showModal" :business="business" :loading="loadingRepayments" @closeModal="showModal = false" />
    <renegotiations-choose-modal v-if="showModalChosse" :business="business" :loading="loadingRepayments" @closeModal="showModalChosse = false" @fluxoOriginal="showModal = true" @fluxoAcordo="fluxoAcordoa" />
    <renegotiation-modal v-if="showModalRenegotiation" :business="business" :acordoId="acordoId" :loading="loadingRepayments" @closeModal="showModalRenegotiation = false" />

    <div class="row my-4">
      <p class="title">Meus investimentos</p>
    </div>
    <div class="row mb-4">
      <investments-alert v-show="!loading" :quantity="data.length" />
    </div>
    <div class="row">
      <div v-if="business" class="portfolio portfolio_desktop">
        <div class="flex justify-center">
          <img class="portfolio_image" :src="business.logo">
        </div>
        <p class="portfolio_title">{{business.empresa.toUpperCase()}}</p>
        <p class="text text-xs">{{`Razão social: ${business.razaoSocial.toUpperCase()}`}}</p>
        <p class="text text-xs">{{`CNPJ: ${business.cnpj}`}}</p>
        <hr class="h-px bg-black w-full opacity-50">
        <div class="text">
          <span class="text_title">Parcelas pagas:</span>
          <span class="text_value">{{business.parcelasPagas}}</span>
        </div>
        <div class="text">
          <span class="text_title">Parcelas restantes:</span>
          <span class="text_value">{{business.parcelasRestantes}}</span>
        </div>
        <hr class="h-px bg-black w-full opacity-50">
        <div class="text">
          <span class="text_title">Valor aportado:</span>
          <span class="text_value">{{business.valorAportado | money}}</span>
        </div>
        <div class="text">
          <span class="text_title">T.I.R:</span>
          <p class="text_value">
            <span>{{business.tirBrutaAoAno}}% </span><span class="text-xs align-top">a.a. </span>
            <span>{{business.tirBrutaAoMes}}% </span><span class="text-xs align-top">a.m.</span>
          </p>
        </div>
        <div class="text">
          <span class="text_title">Rating:</span>
          <span class="text_value">{{business.rating}}</span>
        </div>
        <div class="text">
          <span class="text_title">Valor recebido:</span>
          <span class="text_value">{{(business.valorRecebido + business.valorRecebidoAcordo)| money}}</span>
        </div>
        <div class="text">
          <span class="text_title">Valor a receber:</span>
          <span class="text_value">{{(business.prazoUltimoAcordo > 0 ? business.valorReceberUltimoAcordo : business.valorReceber) | money}}</span>
        </div>
        <v-button size="sm" @click="getCcb" :loading="loadingPdf"><i class="fas fa-file-pdf mr-2"></i>CCB</v-button>
      </div>
      <div v-else-if="loadingPortfolio" class="portfolio portfolio_desktop">
        <i class="fas fa-spinner fa-spin text-secondary text-center"></i>
      </div>
      <div v-else class="portfolio portfolio_desktop text-center">
        <p v-if="data.length > 0">Selecione um investimento</p>
        <p v-else>Não há investimentos</p>
      </div>
      <div class="w-full lg:w-2/3 relative overflow-x-auto lg:overflow-x-hidden">
        <vuetable
          class="investments_table"
          :api-mode="false"
          :fields="header"
          :data="data"
          :row-class="selectedRowClass"
          :no-data-template="loading ? '' : 'Nenhum dado disponível'"
          @vuetable:row-clicked="detail"
        >
          <template v-slot:empresa="props" class="flex items-center">
            {{props.rowData.empresa.toUpperCase()}}
          </template>
          <template v-slot:parcelasRestantes="props" class="flex items-center">
            <span v-if="!props.rowData.quitado && !props.rowData.quitadoPorAcordo && !props.rowData.acordos">{{`${props.rowData.parcelasRestantes} de ${props.rowData.prazo}`}}</span>
            <span v-if="!props.rowData.quitado && !props.rowData.quitadoPorAcordo && props.rowData.acordos">{{`${props.rowData.parcelasRestantesDoUltimoAcordo} de ${props.rowData.prazoUltimoAcordo}`}}</span>

            <span class="text-primary" v-if="props.rowData.quitado">Quitado</span>
            <span class="text-primary" v-if="props.rowData.quitadoPorAcordo">Quitado por acordo</span>

          </template>
          <template v-slot:valorAportado="props" class="flex items-center">
            {{props.rowData.valorAportado | money}}
          </template>
          <template v-slot:tir="props">
            <p>{{(props.rowData.tirBrutaAoAno/100).toFixed(2)}}% a.a</p>
            <p class="text-xss">{{(props.rowData.tirBrutaAoMes/100).toFixed(2)}}% a.m</p>
          </template>
          <template v-slot:detalhes="props" class="flex items-center">
            <v-text-button class="text-primary" @click="chooseModal">
              <span v-if="props.rowData.id == currentLoading" class="fas fa-spinner fa-spin text-secondary text-center"></span>
              <span v-else>Repagamentos</span>
            </v-text-button>
          </template>
        </vuetable>
        <div v-if="loading" class="w-full flex flex-col justify-center items-center mt-4">
          <span class="fa fa-spinner fa-spin text-2xl text-secondary"></span>
        </div>
      </div>
      <div v-if="business" class="portfolio portfolio_mobile">
        <div class="flex justify-center">
          <img class="portfolio_image" :src="business.logo">
        </div>
        <p class="portfolio_title">{{business.empresa}}</p>
        <p class="text text-xs">{{`Razão social: ${business.razaoSocial}`}}</p>
        <p class="text text-xs">{{`CNPJ: ${business.cnpj}`}}</p>
        <hr class="h-px bg-black w-full opacity-50">
        <div class="text">
          <span class="text_title">Parcelas pagas:</span>
          <span class="text_value">{{business.parcelasPagas}}</span>
        </div>
        <div class="text">
          <span class="text_title">Parcelas restantes:</span>
          <span class="text_value">{{business.parcelasRestantes}}</span>
        </div>
        <hr class="h-px bg-black w-full opacity-50">
        <div class="text">
          <span class="text_title">Valor aportado:</span>
          <span class="text_value">{{business.valorAportado | money}}</span>
        </div>
        <div class="text">
          <span class="text_title">T.I.R:</span>
          <p class="text_value">
            <span>{{business.tirBrutaAoAno}}% </span><span class="text-xs align-top">a.a. </span>
            <span>{{business.tirBrutaAoMes}}% </span><span class="text-xs align-top">a.m.</span>
          </p>
        </div>
        <div class="text">
          <span class="text_title">Rating:</span>
          <span class="text_value">{{business.rating}}</span>
        </div>
        <div class="text">
          <span class="text_title">Valor recebido:</span>
          <span class="text_value">{{(business.valorRecebido + business.valorRecebidoAcordo )| money}}</span>
        </div>
        <div class="text">
          <span class="text_title">Valor a receber:</span>
          <span class="text_value">{{business.valorReceber | money}}</span>
        </div>
        <v-button size="sm" @click="getCcb" :loading="loadingPdf"><i class="fas fa-file-pdf mr-2"></i>CCB</v-button>
      </div>
      <div v-else-if="loadingPortfolio" class="portfolio portfolio_mobile">
        <i class="fas fa-spinner fa-spin text-secondary text-center"></i>
      </div>
      <div v-else class="portfolio portfolio_mobile text-center">
        <p>Selecione um investimento</p>
      </div>
    </div>
  </div>
</template>

<script>
import VTextButton from '@/components/Buttons/TextButton'
import InvestmentsAlert from '@/components/Investor/Alerts/InvestmentsAlert'
import VButton from '@/components/Buttons/Button'
import MyInvestmentModal from '@/components/Investor/Modal/MyInvestmentModal'
import ViewFileModal from '@/components/Modal/ViewFileModal'
import RenegotiationsChooseModal from '@/components/Investor/Modal/RenegotiationsChooseModal'

import moneyFilter from '@/mixins/moneyFilter'
import MyInvestments from '@/services/Investor/Investments/MyInvestments'
import RenegotiationModal from '@/components/Investor/Modal/RenegotiationModal'

export default {
  name: 'my-investments',
  components: { VTextButton, InvestmentsAlert, VButton, MyInvestmentModal, ViewFileModal, RenegotiationsChooseModal,RenegotiationModal },
  mixins: [moneyFilter],

    data () {
    return {
      header: [
        {
          name: '__slot:empresa',
          title: 'Tomador'
        },
        {
          name: '__slot:parcelasRestantes',
          title: 'Parcelas Restantes',
          titleClass: 'w-32'
        },
        {
          name: '__slot:valorAportado',
          title: 'Valor aportado'
        },
        {
          name: '__slot:tir',
          title: 'T.I.R.'
        },
        {
          name: '__slot:detalhes',
          title: ''
        }
      ],
      data: [],
      loading: true,
      loadingPdf: false,
      loadingPortfolio: false,
      loadingRepayments: false,
      showModal: false,
      showModalChosse: false,
      business: null,
      blob: null,
      choose : false,
      currentLoading: -1,
      showModalRenegotiation: false,
      acordoId: -1,
    }
  },
  watch:{
    showModalRenegotiation(){
      if(this.showModalRenegotiation){
        document.body.classList.add("modal-open");
      } else{
        document.body.classList.remove("modal-open");
      }
    },
    showModalChosse(){
      if(this.showModalChosse){
        document.body.classList.add("modal-open");
      } else{
        document.body.classList.remove("modal-open");
      }
    },
    showModal(){
      if(this.showModal){
        document.body.classList.add("modal-open");
      } else{
        document.body.classList.remove("modal-open");
      }
    },
  },
  methods: {
    fluxoAcordoa(acordoId){
      this.acordoId = acordoId;
      this.showModalRenegotiation = true;
    },
    chooseModal(){
        this.choose = true;
    },
    selectedRowClass (item) {
      return this.business && item.id === this.business.id && 'investments_table_selected_row'
    },
    async detail (row) {

      this.currentLoading = row.id;

      if (this.business && this.business.id === row.id){
        if(this.choose){
          if(this.business.acordos.length){
            this.showModalChosse = true;
          } else{
            this.showModal = true;
          }
        }

        this.currentLoading = -1;
        return;
      }

      try {
        this.business = null
        this.loadingPortfolio = true
        this.loadingRepayments = true
        this.business = (await MyInvestments.getInvestment(row.id)).data.data

        if(this.choose){
          if(this.business.acordos.length){
            this.showModalChosse = true;
          } else{
            this.showModal = true;
          }
        }

      } catch (error) {
        console.error(error)
      } finally {
        this.loadingPortfolio = false
        this.loadingRepayments = false
      }

      this.currentLoading = -1;
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async getCcb () {
      this.loadingPdf = true
      const file = (await MyInvestments.getInvestmentCcb(this.business.id)).data
console.log(file)
if (file !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(file, `CCB ${this.business.id}.pdf`);
    var a = document.createElement('a');
    var url = window.URL.createObjectURL(file);
    a.setAttribute("href", url);
    a.setAttribute("download", `CCB ${this.business.id}.pdf`);
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();

      //this.blob = await this.getBase64(file)
      this.loadingPdf = false
    }
  },
  async mounted () {
    try {
      this.data = (await MyInvestments.getInvestments()).data.data
      this.loading = false
    } catch (error) {
      console.error(error)
    }
  }
}
</script>

<style lang="sass" scoped>

.row
  @apply w-full flex flex-col
  @screen lg
    @apply flex-row

.title
  @apply font-bold text-xl text-secondary my-2 w-full
  @screen lg
    @apply text-2xl my-auto

.portfolio
  @apply rounded-lg bg-white border shadow p-4 text-xs flex flex-col justify-center
  @screen lg
    @apply sticky h-128 mr-2 text-sm
    top: 1rem
  &_title
    @apply uppercase font-bold text-base mb-2 text-secondary
    @screen lg
      @apply text-center text-lg
  &_image
    @apply mb-3 object-contain rounded-sm
    max-height: 4rem
    @screen lg
      max-height: 6rem
  &_mobile
    @apply mb-4 w-full
    @screen lg
      @apply hidden mb-0
  &_desktop
    @apply hidden
    @screen lg
      @apply flex w-1/3

.text
  @apply flex justify-between leading-loose
  &_title
    @apply text-primary
  &_value
    @apply font-bold

</style>

<style lang="sass">

.vuetable-empty-result
  padding-top: 1rem !important
  padding-bottom: 1rem !important

.investments_table
  @apply w-full bg-white shadow rounded-lg text-sm mb-4
  @screen lg
    @apply mb-0
  &_selected_row
    background-color: rgba(72, 27, 144, 0.2)
  thead tr
    @apply rounded-t-lg
  tbody
    @apply rounded-b-lg
  tr
    @apply cursor-pointer
    &:nth-child(even):not(.investments_table_selected_row):not(:hover)
      @apply bg-gray-100
    &:last-child
      @apply rounded-b-lg
      td:first-child
        @apply rounded-bl-lg
      td:last-child
        @apply rounded-br-lg
    &:hover
      background-color: rgba(72, 27, 144, 0.1)
    th
      @apply p-2 text-gray-600
      background: #ECECEC
    td
      @apply relative text-gray-600 text-center border border-gray-300 py-0 px-2

</style>

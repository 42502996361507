<template>
  <div class="modal">
    <div class="backdrop" @click="$emit('closeModal')"/>
    <div class="container flex justify-center items-center ctlModal">
      <div class="content">
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <div class="flex flex-col lg:flex-row mb-4 px-4 lg:px-0 items-center">
          <div class="w-full md:w-1/2 flex items-center mb-3 lg:mb-0">
            <div class="mr-2"><img class="w-16 object-contain bg-center" :src="business && business.logo"/></div>
            <p class="title">{{business && business.empresa}}</p>
          </div>
          <div class="w-full md:w-1/4">
            <div class="text">
              <span class="text_title">Parcelas pagas:</span>
              <span class="text_value">{{`${business && business.parcelasPagas}/${business && business.prazo}`}}</span>
            </div>
            <div class="text">
              <span class="text_title">Parcelas restantes:</span>
              <span class="text_value">{{business && business.parcelasRestantes}}</span>
            </div>
          </div>
          <div class="w-full md:w-1/4 lg:mx-6">
            <div class="text">
              <span class="text_title">Valor aportado:</span>
              <span class="text_value">{{business && business.valorAportado | money}}</span>
            </div>
            <div class="text">
              <span class="text_title">Valor recebido:</span>
              <span class="text_value">{{business && business.valorRecebido | money}}</span>
            </div>
            <div class="text">
              <span class="text_title">Valor a receber:</span>
              <span class="text_value">{{business && business.valorReceber | money}}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="overflow-x-auto lg:overflow-x-hidden w-full h-full">
            <vuetable
              class="repayments_table"
              :api-mode="false"
              :fields="header"
              :data="(business && business.repagamentos) || []"
              :no-data-template="loading ? '' : 'Nenhum dado retornado'"
            >
              <template v-slot:dataResgate="props">
                <span class="font-bold">{{$moment(props.rowData.dataResgate).format('DD/MM/YYYY')}}</span>
              </template>
              <template v-slot:status="props">
                <span class="whitespace-no-wrap" :class="props.rowData.status === 'Pago' ? 'text-primary' : props.rowData.status === 'Em atraso' ? 'text-red-600' : 'text-yellow-500'">
                  {{props.rowData.status}}
                </span>
              </template>
              <template v-slot:juros="props">
                {{props.rowData.juros | money}}
              </template>
              <template v-slot:amortizacao="props">
                {{props.rowData.amortizacao | money}}
              </template>
              <template v-slot:ir="props">
                {{props.rowData.ir | money}}
              </template>
              <template v-slot:bruto="props">
                {{props.rowData.bruto | money}}
              </template>
              <template v-slot:outros="props">
                {{props.rowData.outros | money}}
              </template>
              <template v-slot:totalRecebido="props">
                {{props.rowData.totalRecebido | money}}
              </template>
              <template v-slot:liquido="props">
                {{props.rowData.liquido | money}}
              </template>
            </vuetable>
          </div>
          <div v-if="loading" class="w-full flex flex-col justify-center items-center mt-4">
            <span class="fa fa-spinner fa-spin text-lg text-secondary"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoneyFilter from '@/mixins/moneyFilter'

export default {
  name: 'modal-my-investment',
  props: ['business', 'loading'],
  mixins: [MoneyFilter],
  data () {
    return {
      header: [
        {
          name: '__slot:dataResgate',
          title: 'Resgate'
        },
        {
          name: '__slot:status',
          title: 'Status'
        },
        {
          name: '__slot:juros',
          title: 'Juros'
        },
        {
          name: '__slot:amortizacao',
          title: 'Amortização'
        },
        {
          name: '__slot:ir',
          title: 'IR'
        },
        {
          name: '__slot:bruto',
          title: 'Bruto'
        },
        {
          name: '__slot:outros',
          title: 'Outros'
        },
        {
          name: '__slot:totalRecebido',
          title: 'Total recebido'
        },
        {
          name: '__slot:liquido',
          title: 'Líquido'
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.ctlModal
  position: absolute
  padding: 0
  @screen lg
    margin-top: 0

.ctlModal > div
    padding: 0
    padding-top: 16px
    @screen md
      @apply p-4

.modal
  @apply fixed flex items-center justify-center w-screen h-screen top-0 left-0 z-50
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)
  box-sizing: border-box
  border-width: 0
  border-style: solid
  border-color: #e2e8f0
.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)


.content
  @apply relative w-full max-h-screen overflow-y-auto bg-white z-50 flex flex-col pt-4
  @screen md
   @apply rounded-lg
  @screen lg
    @apply w-2/3 rounded-lg


.title
  @apply text-secondary text-sm font-bold whitespace-no-wrap
  @screen lg
    @apply text-base

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

.text
  @apply text-xss whitespace-no-wrap text-secondary opacity-75 w-full flex justify-between
  @screen lg
    @apply text-xs

::-webkit-scrollbar
  @screen md
    width: 8px
  @screen lg
    width: 8px

::-webkit-scrollbar-track
  @screen md
    @apply bg-gray-400 rounded-lg
  @screen lg
    @apply bg-gray-400 rounded-lg

::-webkit-scrollbar-thumb
  @screen md
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
  @screen lg
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
</style>

<style lang="sass">

.repayments_table
  @apply w-full bg-white shadow rounded-lg mb-4
  thead tr
    @apply rounded-t-lg
  tbody
    @apply rounded-b-lg
  tr
    &:nth-child(even)
      @apply bg-gray-100
    &:last-child
      @apply rounded-b-lg
      td:first-child
        @apply rounded-bl-lg
      td:last-child
        @apply rounded-br-lg
    th
      @apply p-2 text-sm text-gray-600
      background: #ECECEC
    td
      @apply relative text-gray-600 text-center text-sm border border-gray-300 p-1

</style>

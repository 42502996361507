<template>
  <div class="modal">
    <div class="backdrop" @click="$emit('closeModal')" />
    <div class="container flex justify-center items-center ctlModal">
      <div class="content">
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>
        <div class="flex flex-col lg:flex-row mt-5 md:mt-0 mb-1 px-4 lg:px-0 items-center">
          <div class="w-full md:w-1/1 flex items-center mb-3 lg:mb-0">
            <div class="mr-2">
              <img
                class="w-16 object-contain bg-center"
                :src="business && business.logo"
              />
            </div>
            <p class="title" style="white-space: break-spaces;">
              {{ business && business.empresa }}
            </p>
          </div>
        </div>
        <div class="flex flex-col lg:flex-row mb-4 px-4 lg:px-0 items-center">
          <div class="w-full md:w-1/1">
            <div class="text">
              Essa empresa pussuí
              {{
                business.acordos.length > 1 ? "acordos ativos" : "acordo atívo"
              }}, selecione um fluxo abaixo:
            </div>
          </div>
        </div>
        <div class="row">
          <div class="overflow-x-auto lg:overflow-x-hidden w-full h-full">
            <table class="investments_table">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Status</th>
                  <th>Prestação</th>
                  <th class="cell hidden md:block">Parc. Pagas</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="a in business.acordos" v-bind:key="a.id">
                  <td>{{ $moment(a.data).format("DD/MM/YYYY") }}</td>
                  <td>{{ a.status }}</td>
                  <td>{{ a.parcela | money }}</td>
                  <td class="cell hidden md:block">
                    {{ a.parcelasPagas }}/{{ a.prazo }}
                  </td>
                  <td>
                    <a
                      href="javascript://"
                      v-on:click="$emit('fluxoAcordo', a.id)"
                      >Ver fluxo
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <br />
            <hr />
            <br />

            <div class="text-center md:text-right mr-2 ml-2 md:mr-0 md:ml-0">
              <button
                v-on:click="
                  $emit('closeModal');
                  $emit('fluxoOriginal');
                "
                class="btnFluxoOriginal bg-transparent hover:bg-gray-500 text-xs text-gray-700 font-semibold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded"
              >
                Ver fluxo original
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoneyFilter from "@/mixins/moneyFilter";

export default {
  name: "renegotiations-choose-modal",
  props: ["business", "loading"],
  mixins: [MoneyFilter],
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="sass" scoped>
.none
  @screen sm
  display: none

.ctlModal
  position: absolute
  padding: 0
  height: 100%
  @screen lg
    margin-top: 0
    height: auto

.ctlModal > div
    padding: 0
    height: 100%
    @screen md
      @apply p-4
      height: auto

.btnFluxoOriginal
  width: 100%
  @screen md
    width: auto


.modal
  @apply fixed flex items-center justify-center w-screen h-screen top-0 left-0 z-50


.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.content
  @apply relative w-full max-h-screen overflow-y-auto bg-white z-50 flex flex-col pt-4
  @screen md
   @apply rounded-lg
  @screen lg
    @apply w-11/12 px-4 rounded-lg
    min-height: 20rem

.title
  @apply text-secondary text-sm font-bold whitespace-no-wrap
  @screen lg
    @apply text-base

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

.text
  @apply text-xss whitespace-no-wrap text-secondary opacity-75 w-full flex justify-between
  @screen lg
    @apply text-xs

::-webkit-scrollbar
  @screen md
    width: 8px
  @screen lg
    width: 8px

::-webkit-scrollbar-track
  @screen md
    @apply bg-gray-400 rounded-lg
  @screen lg
    @apply bg-gray-400 rounded-lg

::-webkit-scrollbar-thumb
  @screen md
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
  @screen lg
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
</style>

import api from '@/plugins/axios'

const base = 'v1/investidor/'

export default {
  getInvestments () {
    return api.get(`${base}investimentos`)
  },
  getInvestment (id) {
    return api.get(`${base}investimento/${id}`)
  },
  getInvestmentCcb (id) {
    return api.get(`${base}investimento/${id}/ccb`, { responseType: 'blob' })
  }
}
